import axios from "axios";

const URL = process.env.VUE_APP_baseURL;
const baseApiClient = axios.create({
  baseURL: URL,
  // baseURL:  process.env.VUE_APP_baseURL,
  baseURL: "https://api.profilematcher.in",
  // baseURL: "http://localhost:49155",
  // baseURL: "http://192.168.1.33:49155",




  headers: {
    Accept: "application/JSON",
    "Content-Type": "application/JSON"
  }
});

export default {

  GetAuthentication(body) {
    return baseApiClient.post("/login", body);
  },
  SignupValidation(body) {
    return baseApiClient.post("/signup", body);
  },
  ProfileMatcher(clientId) {
    const hdr = {
      headers: { ClientId: clientId },
    };
    return baseApiClient.get("/profileMaster", hdr);
  },
  userProfile(clientId) {
    // console.log(clientId,"clientIdsadas");
    const hdr = {
      headers: { ClientId: clientId },
    };
    return baseApiClient.get("/getuserProfile", hdr);
  },
  SetUserProfile(body, Indicator) {
    const hdr = {
      headers: { Indicator: Indicator },
    };
    return baseApiClient.post("/setuserProfile", body, hdr);
  },
  SetProfileImg(formData, clientId) {
    const hdr = {
      headers: { ClientId: clientId },
    };
    return baseApiClient.post("/fileUploader", formData, hdr);
  },
  GetUserChats(ClientId) {
    const hdr = {
      headers: { ClientId: ClientId },
    };
    return baseApiClient.get("/getChats", hdr);
  },
  SetUserChats(body) {
    return baseApiClient.post("/setuserChats", body);
  },
  Checkuser(body) {
    return baseApiClient.post("/forgetPassword", body);

  },
  SearchUser(value) {
    const hdr = {
      headers: { ClientId: value },
    };
    return baseApiClient.get("/searchProfile", hdr);
  },
  GetpincodeDetails(value) {
    const hdr = {
      headers: { Pincode: value },
    };
    
    return baseApiClient.get("/getPincodeDetails",hdr);
  },

  Logout(body){
    return baseApiClient.post("/logout", body);
  },

  // ========================== LookUp Addtion ================================

  GetLookUpHeader() {
    return baseApiClient.get("/LookUpHeader");
  },
  FetchLookUpDetails(id) {
    return baseApiClient.put("/LookUpDetails", id);
  },
  AddLookUpHeader(input) {
    return baseApiClient.put("/InsertHeader", input);
  },
  AddLookUpDetails(input) {
    return baseApiClient.put("/InsertDetails", input);
  },
  UpdateLookUpHeader(UpdateItem) {
    return baseApiClient.put("/UpdateHeader", UpdateItem);
  },
  UpdateLookUpDetails(input) {
    return baseApiClient.put("/UpdateDetails", input);
  },


};
