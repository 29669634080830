<template>
  <div>
    <v-card class="elevation-0" v-if="userProfile.userInfo != undefined">
      <v-row>
        <v-col lg="3" sm="3" md="3" cols="12">
          <v-img v-if="userProfile.userInfo.profilepic != ''" :src="userProfile.userInfo.profilepic" contain
            :aspect-ratio="1" @mouseover="showoverlay = true" @mouseleave="showoverlay = false">
            <v-overlay absolute top right :value="showoverlay">
              <v-file-input accept=".jpg, .jpeg, .png" v-model="pic" hide-input prepend-icon="mdi-upload"
                @change="$emit('profilepic', pic)"></v-file-input>
            </v-overlay>
          </v-img>
          <v-img v-else aspect-ratio="1" :src="profilePicture(userProfile)" @mouseover="showoverlay = true"
            @mouseleave="showoverlay = false">
            <v-overlay absolute top right :value="showoverlay">
              <v-file-input accept=".jpg, .jpeg, .png" v-model="pic" hide-input prepend-icon="mdi-upload"
                @change="$emit('profilepic', pic)"></v-file-input>
            </v-overlay>
          </v-img>
        </v-col>
        <!-- <v-col cols="12" class="d-flex justify-center" v-if="$vuetify.breakpoint.width < 600 ">
          <v-btn class=" white--text" color="pink" outlined @click="triggerFileUpload">
            <v-icon>mdi-upload</v-icon> Upload Profile
          </v-btn>
        </v-col> -->
        <v-col lg="9" sm="9" md="9" cols="12">
          <v-card-title class=" text-uppercase d-flex ma-2">
            {{ userProfile.userInfo.name }}
            <!-- Prashanth -->
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider class="ma-2"></v-divider>
          <v-row class="ma-2">
            <v-col lg="6" sm="6" md="6" cols="12">
              <!-- <span><v-icon  class="pink--text">mdi-calendar-outline</v-icon> {{  userProfile.dob }},   <v-icon class="pink--text">mdi-human-male-height</v-icon> {{ handleEmpty(userProfile.user_height) }}</span> -->
              <span><v-icon class="pink--text">mdi-calendar-outline</v-icon> {{ handleEmpty(
                calculateAge(userProfile.userInfo.dob)) }}, <v-icon class="pink--text">mdi-human-male-height</v-icon> {{
                  handleEmpty(userProfile.userInfo.height) }}</span>

            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span> <v-icon class="pink--text">mdi-account-multiple</v-icon>
                <!-- Never marrried -->
                {{ handleEmpty(userProfile.userInfo.martialStatus) }}
              </span>
              <!-- Never Married -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-account-group</v-icon> {{
                handleEmpty(userProfile.religiousBackGround.religion) }},{{
                  handleEmpty(userProfile.religiousBackGround.caste) }}</span>
              <!-- Hindu,Naidu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span>
                <v-icon class="pink--text">mdi-home-outline</v-icon>
                {{ handleEmpty(userProfile.locations.cityofResidence) }},{{
                  handleEmpty(userProfile.locations.stateofResidence) }}
              </span>
              <!-- chennai,TamilNadu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              {{ handleEmpty(userProfile.religiousBackGround.motherTongue) }}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-briefcase-outline</v-icon></span> {{
                handleEmpty(userProfile.carrers.workingAs) }},{{ handleEmpty(userProfile.carrers.annualIncome) }}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="12" sm="12" md="12" cols="12">
              <span> {{ handleEmpty(userProfile.userInfo.about) }}</span>
              <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, eius enim sapiente assumenda facere esse eligendi ab, impedit qui, dolore provident totam quis molestias officia et sit harum voluptates veniam. -->
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="4">
          <v-btn class=" white--text" color="pink" outlined>
            <v-icon>mdi-upload</v-icon> Upload Profile
          </v-btn>
        </v-col> -->
      </v-row>
    </v-card>

  </div>
</template>
<script>
export default {
  data() {
    return {
      showoverlay: false,
      // profile:"https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180",
      profile: "",

      pic: null
    }
  },
  props: {
    userProfile: {}
  },
  methods: {
    handleEmpty(value) {
      if (value == "") {
        return "Not Mentioned"
      } else {
        return value
      }


    },
    triggerFileUpload() {
      // Trigger the file input click programmatically
      this.$refs.fileInput.$el.click();
    },
    profilePicture(client) {
      if (client.userInfo.profilepic !== '') {
        return client.userInfo.profilepic;
      }
      if (client.userInfo.gender === 'M') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
      }
      if (client.userInfo.gender === 'F') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
      }
      return 'https://via.placeholder.com/300';
    },
    calculateAge(value) {
      if (value) {
        const today = new Date();
        const dobDate = new Date(value);
        const diff = today.getTime() - dobDate.getTime();
        const ageDate = new Date(diff);

        const years = Math.abs(ageDate.getUTCFullYear() - 1970);
        const months = ageDate.getUTCMonth();

        let ageString = '';

        if (years > 0) {
          ageString += years + (years === 1 ? ' yr ' : ' yrs ');
        }

        if (months > 0) {
          ageString += months + (months === 1 ? ' mon' : ' mons');
        }
        return ageString.trim();
      } else {
        return "Not Mentioned"
      }
    },
  }
}
</script>